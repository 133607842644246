import React, { Component } from "react";
import { compose } from "redux";
import { FormattedMessage, injectIntl } from "react-intl";
import bindActionCreatorHoc from "../../libraries/bindActionCreatorHoc";
import { withRouter } from "react-router-dom";
import { Card, CardGroup, Col, Container, Row } from "reactstrap";

class RedirectInternal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: ""
    };

    this.clearToken = this.clearToken.bind(this);
  }

  clearToken() {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
  }

  componentWillReceiveProps(nextProps) {
    const { token, history } = nextProps;

    if (token && token !== "") {
      history.push("/");
    }
  }

  componentWillMount() {
    const hash = window.location.hash;
    const search = hash ? hash.substring(hash.indexOf("?")) : "";
    const urlSearchParams = new URLSearchParams(search);

    const redirectToken = urlSearchParams.get("token");
    if (!redirectToken) {
      return;
    }

    this.clearToken();

    this.props.authAction
      .verifyRedirectToken(redirectToken)
      .then(() => {
        this.props.history.push("/");
      })
      .catch(error => {
        const { body } = error;
        const { message } = body;
        this.setState({ errorMessage: message });
      });
  }

  render() {
    const { errorMessage } = this.state;

    return (
      <>
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="6">
                <CardGroup>
                  <Card className="p-4">
                    {errorMessage && (
                      <>
                        <FormattedMessage id="internal.redirect.error" />
                        {errorMessage}
                      </>
                    )}
                    {!errorMessage && <div>Loading...</div>}
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default compose(
  injectIntl,
  withRouter,
  bindActionCreatorHoc
)(RedirectInternal);
