import {CALL_API} from '../libraries/middleware';
import actionCreator from '../libraries/actionCreator';

interface MenuQueryObject {
    store: number;
    menu: number;
}

interface MenuObject {
    id: number;
    title: String;
    body: object;
  defaultVat: number;
}

interface CategoryObject {
    id: number;
    body: object;
}

interface ItemObject {
    id: number;
    body: object;
}

interface AttributeObject {
    id: number;
    body: object;
}

interface StoreObject {
    storeUuid: string;
}



export const { Actions, Constants } = actionCreator({

    getBusinessMenuGroup() {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/menu/group`
            }
        }
    },

    saveMenuGroup(data: object) {
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/menu/group`,
                body: data,
            }
        };
    },

    deleteMenuGroup(id: number) {
        return {
            [CALL_API]: {
                method: 'DELETE',
                path: `/menu/group/${id}`,
            }
        };
    },

    getAllLangs() {
        return {
            [CALL_API]: {
                method: 'GET',
                path: '/business/langs',
            },
        };
    },

    importMenu(formData: object) {
        return {
            [CALL_API]: {
                method: 'POST',
                path: '/menu/import',
                body: formData,
                apiType: 'multipart'
            },
        };
    },

    downloadMenu(data: object) {
        return {
            [CALL_API]: {
                method: 'POST',
                path: '/menu/export',
                apiType: 'binary',
                body: data
            },
        };
    },

    getAllMenu() {
        return {
            [CALL_API]: {
                method: 'GET',
                path: '/menu',
            },
        };
    },

    getAllMenuItems() {
        return {
            [CALL_API]: {
                method: 'GET',
                path: '/menu/items',
            },
        };
    },

    addMenu(formData: MenuObject) {
        const { body } = formData;
        return {
            [CALL_API]: {
                method: 'POST',
                path: '/menu',
                body: body,
            },
        };
    },

    deleteMenu(formData: MenuObject) {
        const { id } = formData;
        return {
            [CALL_API]: {
                method: 'DELETE',
                path: `/menu/${id}`,
            },
        };
    },

  updateMenuInfo(formData: MenuObject) {
    const {id, title, defaultVat} = formData;
        return {
            [CALL_API]: {
                method: 'PUT',
                path: `/menu/${id}/title/${title}`,
              body: {
                title,
                defaultVat
              },
            },
        };
    },

    getMenuScheduleOfStore(formData: MenuQueryObject) {
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/menu/store/menu-schedule`,
                body: formData
            },
        };
    },

    getMenuSchedule(formData: MenuQueryObject) {
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/menu/all-menu-schedule`,
                body: formData
            },
        };
    },

    addMenuSchedule(formData: object) {
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/menu/menu-schedule`,
                body: formData
            },
        };
    },

    updateMenuSchedule(formData: object) {
        return {
            [CALL_API]: {
                method: 'PUT',
                path: `/menu/menu-schedule`,
                body: formData
            },
        };
    },

    deleteMenuSchedule(formData: MenuObject) {
        const { id } = formData;
        return {
            [CALL_API]: {
                method: 'DELETE',
                path: `/menu/${id}/menu-schedule`
            },
        };
    },

    getMenuCategory(formData: MenuObject) {
        const { id } = formData;
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/menu/${id}/category`
            },
        };
    },

    addCategory(formData: MenuObject) {
        const { id, body } = formData;
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/category/${id}/addAndImage`,
                body: body,
                apiType: 'multipart'
            },
        };
    },

    updateCategory(formData: CategoryObject) {
        const { id, body } = formData;
        return {
            [CALL_API]: {
                method: 'PUT',
                path: `/category/${id}/updateAndImage`,
                body: body,
                apiType: 'multipart'
            },
        };
    },

    updateCategoryPosition(formData: CategoryObject) {
        const { id, body } = formData;
        return {
            [CALL_API]: {
                method: 'PUT',
                path: `/category/${id}/position/${body}`,
            },
        };
    },

    updateCategoriesPosition(body: Object) {
        return {
            [CALL_API]: {
                method: 'PUT',
                path: `/category/position`,
                body: body
            },
        };
    },

    relateCategoryAndItems(formData: CategoryObject) {
        const { id, body } = formData;
        return {
            [CALL_API]: {
                method: 'PUT',
                path: `/category/${id}/relate`,
                body: body,
            },
        };
    },

    getCategory(id:number) {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/category/${id}`,
            },
        };
    },

    deleteCategory(formData: CategoryObject) {
        const { id } = formData;
        return {
            [CALL_API]: {
                method: 'DELETE',
                path: `/category/${id}`,
            },
        };
    },

    getMenuItem(formData: MenuObject) {
        const { id } = formData;
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/item/${id}/listItem`
            },
        };
    },

    getCategoryItem(formData: CategoryObject) {
        const { id } = formData;
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/category/${id}/item`
            },
        };
    },

    createItem(formData: ItemObject) {
        const { id, body } = formData;
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/menu/${id}/item/add`,
                body: body,
                apiType: 'multipart'
            },
        };
    },

    updateItem(formData: ItemObject) {
        const { id, body } = formData;
        return {
            [CALL_API]: {
                method: 'PUT',
                path: `/item/${id}/item/update`,
                body: body,
                apiType: 'multipart'
            },
        };
    },

    updateItemPosition(formData: ItemObject) {
        const { id, body } = formData;
        return {
            [CALL_API]: {
                method: 'PUT',
                path: `/item/${id}/position/${body}`,
            },
        };
    },

    updateItemsPosition(body: Object) {
        return {
            [CALL_API]: {
                method: 'PUT',
                path: `/item/position`,
                body: body,
            },
        };
    },

    getItem(id:number) {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/item/${id}`,
            },
        };
    },

    deleteItem(formData: ItemObject) {
        const { id } = formData;
        return {
            [CALL_API]: {
                method: 'DELETE',
                path: `/item/${id}`,
            },
        };
    },

    getUncategorizedItems(formData: CategoryObject) {
        const { id } = formData;
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/item/${id}/getUncategorizedItems`
            },
        };
    },

    relateItemAndAttrs(formData: ItemObject) {
        const { id, body } = formData;
        return {
            [CALL_API]: {
                method: 'PUT',
                path: `/item/${id}/relate`,
                body: body,
            },
        };
    },

    uploadItemImage(formData: ItemObject) {
        const { id, body } = formData;
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/item/${id}/image`,
                body: body,
                apiType: 'multipart'
            },
        };
    },

    getMenuAttribute(formData: MenuObject) {
        const { id } = formData;
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/item-attribute/${id}/listMenuAttribute`
            },
        };
    },

    getItemAttribute(formData: ItemObject) {
        const { id } = formData;
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/item-attribute/${id}/listAttribute`
            },
        };
    },

    addMenuAttribute(formData: AttributeObject) {
        const { id, body } = formData;
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/item-attribute/${id}/attribute/add`,
                body: body,
            },
        };
    },

    addItemAttribute(formData: AttributeObject) {
        const { id, body } = formData;
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/item-attribute/${id}/create`,
                body: body,
            },
        };
    },

    updateAttribute(formData: AttributeObject) {
        const { id, body } = formData;
        return {
            [CALL_API]: {
                method: 'PUT',
                path: `/item-attribute/${id}/update`,
                body: body,
            },
        };
    },

    updateAttributePosition(formData: AttributeObject) {
        const { id, body } = formData;
        return {
            [CALL_API]: {
                method: 'PUT',
                path: `/item-attribute/${id}/position/${body}`,
            },
        };
    },

    updateAttributesPosition(body: Object) {
        return {
            [CALL_API]: {
                method: 'PUT',
                path: `/item-attribute/position`,
                body: body,
            },
        };
    },

    deleteAttribute(formData: AttributeObject) {
        const { id } = formData;
        return {
            [CALL_API]: {
                method: 'DELETE',
                path: `/item-attribute/${id}`,
            },
        };
    },

    getAttributeOption(formData: AttributeObject) {
        const { id } = formData;
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/item-attribute/${id}/listOption`
            },
        };
    },

    createAttributeOption(formData: AttributeObject) {
        const { id, body } = formData;
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/item-attribute/${id}/option`,
                body: body,
            },
        };
    },

    updateOption(formData: AttributeObject) {
        const { id, body } = formData;
        return {
            [CALL_API]: {
                method: 'PUT',
                path: `/item-attribute/option/${id}`,
                body: body,
            },
        };
    },

    deleteOption(formData: AttributeObject) {
        const { id } = formData;
        return {
            [CALL_API]: {
                method: 'DELETE',
                path: `/item-attribute/option/${id}`,
            },
        };
    },

    uploadOptionImage(formData: AttributeObject) {
        const { id, body } = formData;
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/item-attribute/${id}/image`,
                body: body,
                apiType: 'multipart'
            },
        };
    },

    relateAttrAndItems(formData: AttributeObject) {
        const { id, body } = formData;
        return {
            [CALL_API]: {
                method: 'PUT',
                path: `/item-attribute/${id}/relate`,
                body: body,
            },
        };
    },

    updateMenu(formData: StoreObject) {
        const { storeUuid } = formData;
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/menu/notifyUpdate/${storeUuid}`
            },
        };
    },

    deleteItemImage(formData: ItemObject) {
        const { id } = formData;
        return {
            [CALL_API]: {
                method: 'DELETE',
                path: `/item/${id}/image`,
            },
        };
    },

    deleteCategoryImage(formData: CategoryObject) {
        const { id } = formData;
        return {
            [CALL_API]: {
                method: 'DELETE',
                path: `/category/${id}/image`,
            },
        };
    },

    deleteCategoryBrandImage(formData: CategoryObject) {
        const { id } = formData;
        return {
            [CALL_API]: {
                method: 'DELETE',
                path: `/category/${id}/brand-image`,
            },
        };
    },

    getMenuItemWhenOrderItems(formData: MenuObject) {
        const { id } = formData;
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/menu/${id}/itemWhenOrderItems`
            },
        };
    },

    updateMenuItemWhenOrderItems(formData: MenuObject) {
        const { id, body } = formData;
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/menu/${id}/itemWhenOrderItems`,
                body: body,
            },
        };
    },

    getItemSkuOption() {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/menu/getItemSkuOption`
            },
        };
    },

    getMenuStore() {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/menu/getAllStores`
            },
        };
    },

    getMenuStoreUuid() {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/menu/getAllStores/uuid`
            },
        };
    },

    getAllSimpleMenu() {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/menu/simple/platform/DEFAULT`,
            },
        };
    },

    getFoodpandaSimpleMenu() {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/menu/simple/platform/FOODPANDA`,
            },
        };
    },

    getPlatformMenu(menuId: number) {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/menu/platform/${menuId}`,
            },
        };
    },

    foodpandaCheckImportable(storeId: number) {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/menu-integration/foodpanda/check-importable/${storeId}`,
            },
        };
    },

    foodpandaImport(storeId: number) {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/menu-integration/foodpanda/import/${storeId}`,
            },
        };
    },

    foodpandaImportResult(storeId: number) {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/menu-integration/foodpanda/import-result/${storeId}`,
            },
        };
    },

    foodpandaImportResults(storeIds: Object) {
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/menu-integration/foodpanda/import-result`,
                body: storeIds
            },
        };
    },

    updateFoodpandaCategory(id:number, body: Object) {
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/category/${id}/platform`,
                body: body
            },
        };
    },

    getMenuOption(type:string) {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/menu/option/${type}`,
            },
        };
    },

    updateCategoryItem(menuId:number, categoryId:number, body: Object) {
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/menu-relation/${menuId}/category/${categoryId}/item`,
                body: body
            },
        };
    },

    updateMenuCategory(menuId:number, body: Object) {
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/menu-relation/${menuId}/category`,
                body: body
            },
        };
    },

    updatePlatformMenuSchedule(menuId:number, body: Object) {
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/menu/platform/${menuId}/schedule`,
                body: body
            },
        };
    },

    deletePlatformMenuSchedule(menuId:number, scheduleId:number) {
        return {
            [CALL_API]: {
                method: 'DELETE',
                path: `/menu/platform/${menuId}/schedule/${scheduleId}`
            },
        };
    },

    updateFoodpandaItem(menuId:number, body:object) {
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/item/platform/${menuId}/update`,
                body: body
            },
        };
    },

    syncFoodpandaMenu(storeId:number, menuId:number, externalKey:String) {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/menu-integration/foodpanda/${storeId}/menu/${menuId}/update/${externalKey}`,
            },
        };
    },

    updatePopularAttributes(body: Object) {
        return {
            [CALL_API]: {
                method: 'PUT',
                path: `/item/popular-attributes`,
                body: body,
            },
        };
    },

});