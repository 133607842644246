import { Constants } from "../actions/auth";

const initialState = {
  token: localStorage.token,
  loginMsgId: null,
  currentUser: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case Constants.loginSuccess:
        case Constants.verifyRedirectTokenSuccess:
            if (action.response.authorities === 'ROLE_BUSINESS_OWNER'||
                action.response.authorities === 'ROLE_INTERNAL_USER_BUSINESS') {
                localStorage.token = action.response.token;
                localStorage.refreshToken = action.response.refreshToken;
                return {
                    ...state,
                    token: action.response.token
                };
            } else {
                return {
                    ...state,
                    loginMsgId: 'api.error.99'
                };
            }
        case Constants.logout:
            delete localStorage.token;
            delete localStorage.refreshToken;
            delete localStorage.email;
            return {
                ...state,
                token: null,
                loginMsgId: null,
            };
        case Constants.refreshTokenSuccess:
            localStorage.token = action.response.token;
            return state;
        case Constants.setLoginMessage:
            return {
                ...state,
                loginMsgId: action.id
            };
        case Constants.clearLoginMessage:
            return {
                ...state,
                loginMsgId: null
            };
        case Constants.getCurrentUserSuccess:
            return {
                ...state,
                currentUser: action.response
            };
        default:
            return state;
    }
}
