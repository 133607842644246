import {
  Actions as authAction,
  Constants as authConstants
} from "../actions/auth";
import { Actions as commonAction } from "../actions/common";
import { applyMiddleware, compose, createStore } from "redux";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "../reducers";
import apiMiddleman, { CALL_API } from "../libraries/middleware";

const logger = createLogger({
  level: "info",
  collapsed: true,
  logger: console,
  predicate: () => true
});

const createAPI = () => apiMiddleman({
    baseUrl: `${process.env.REACT_APP_API_BASE}/api`,
    generateDefaultParams: () => {
        return {
            headers: {
                'X-Authorization': localStorage.token ? `Bearer ${localStorage.token}` : '',
                'Content-Type': 'application/json'
            }
        };
    },
    defaultSendingType: () => {
        return { type: '@@API_CALL' };
    },
    defaultProcessBeforeRequest: (dispatch, getState) => {
        if (!getState().common.isApiAnimationOpen) {
            dispatch(commonAction.openLoadingAnimation());
        }
    },
    defaultProcessAfterSuccess: (dispatch, apiAction, getState) => {
        if (getState().common.isApiAnimationOpen &&
            apiAction[CALL_API].successType.type !== authConstants.refreshTokenSuccess) {
            dispatch(commonAction.closeLoadingAnimation());
        }
    },
    defaultProcessAfterError: (dispatch, getState) => {
        if (getState().common.isApiAnimationOpen) {
            dispatch(commonAction.closeLoadingAnimation());
        }
    },
    validateResponseBody: (resBody, dispatch) => {
        if (resBody === null) {
            return false;
        }
        if (resBody.errorCode !== undefined) {
            dispatch(commonAction.closeLoadingAnimation());
            return false;
        }
        return true;
    },
    errorInterceptor: ({ err, proceedError, dispatch, replay }) => {
        if (err.status === 401) {
            const { url, method } = err.response.req;
            const errResponse = JSON.parse(err.response.text);
            if (url === `${process.env.REACT_APP_API_BASE}/api/auth/login` && method === 'POST') {
                dispatch(commonAction.closeLoadingAnimation());
                dispatch(authAction.setLoginMessage(`api.error.${errResponse.errorCode}`));
            } else if (url === `${process.env.REACT_APP_API_BASE}/api/auth/redirect-token` && method === 'POST') {
                dispatch(commonAction.closeLoadingAnimation());
                dispatch(authAction.setLoginMessage(`api.error.${errResponse.errorCode}`));
            } else if (url !== `${process.env.REACT_APP_API_BASE}/api/auth/token` || method !== 'POST') {
                dispatch(authAction.refreshToken(localStorage.refreshToken))
                    .then(() => {
                        replay();
                    }, () => {
                        dispatch({ type: authConstants.logout });
                        dispatch(authAction.setLoginMessage(`api.error.${errResponse ? errResponse.errorCode : 11}`));
                    });
            } else {
                proceedError();
            }
        } else if (err.status === 500) {
            // const errResponse = JSON.parse(err.response.text);
            dispatch(commonAction.closeLoadingAnimation());
            // if (errResponse.message === 'CR-0001') {
            //     dispatch(authAction.setLoginMessage('error.message.CR-0001'));
            // } else {
            //     dispatch(localeAction.fireServerError(errResponse.message));
            // }
        } else if (err.timeout !== undefined) {
            dispatch(commonAction.closeLoadingAnimation());
            // dispatch({ type: commonConstants.toggleRequestTimeout });
        } else {
            proceedError();
        }
    }
});

export default function configureStore(initialState) {
    let createStoreWithMiddleware;

    if (process.env.NODE_ENV !== 'production') {
        createStoreWithMiddleware = compose(
            applyMiddleware(reduxImmutableStateInvariant()),
            applyMiddleware(thunk),
            applyMiddleware(createAPI()),
            applyMiddleware(logger),
            window.devToolsExtension ? window.devToolsExtension() : f => f
        ) (createStore);
    } else {
        createStoreWithMiddleware = compose(
            applyMiddleware(thunk),
            applyMiddleware(createAPI()),
        ) (createStore);
    }
    const store = createStoreWithMiddleware(rootReducer, initialState);

    // Enable Webpack hot module replacement for reducers
    if (module.hot) {
        module.hot.accept('../reducers', () =>
            store.replaceReducer(rootReducer)
        );
    }

    return store;
}
