import React, { Component } from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import { f_locale } from ".";
import IntlWrapper from "./libraries/IntlWrapper";
import "./App.scss";
import "@wojtekmaj/react-timerange-picker/dist/TimeRangePicker.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-clock/dist/Clock.css";
import RedirectInternal from "./views/RedirectInternal/RedirectInternal";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading
});

const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading
});

const PagePrivacy = Loadable({
  loader: () => import('./views/Pages/PagePrivacy'),
  loading
});

class App extends Component {
  render() {
    const store = configureStore();
    const { f_messages } = this.props;

    return (
      <Provider store={store}>
        <IntlWrapper isRoute={true} f_language={{ f_locale, f_messages }}>
          <HashRouter>
            <Switch>
              <Route exact path="/login" name="Login Page" render={() => (
                localStorage.getItem('token') ? (
                  <Redirect to="/" />
                ) : (
                  <Login />
                )
              )}/>
              <Route exact path="/404" name="Page 404" component={Page404} />
              <Route exact path="/500" name="Page 500" component={Page500} />
              <Route path="/privacy" name="Page Privacy" component={PagePrivacy} />
              <Route exact path="/redirect/internal" name="Redirect Internal" render={() => (
                <RedirectInternal/>
              )}/>
              <Route path="/" name="Home" render={(props) => (
                localStorage.getItem('token') ? (
                  <DefaultLayout {...props} />
                ) : (
                  <Redirect to="/login" />
                )
              )}/>
            </Switch>
          </HashRouter>
        </IntlWrapper>
      </Provider>
    );
  }
}

export default App;
